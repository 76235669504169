<template>
  <div
    class="markabox markaall"
    :class="{ rejected: brand.brandStatus === 'REJECTED' }"
  >
    <div class="markaline markainfo">
      <div class="markaname">{{ brand.name }}</div>
      <div class="belge" v-if="fileName">
        <img src="@/assets/img/file_pdf.svg" alt class="bimg" />
        <div>
          <div class="belgename">{{ fileName | toFileName }}</div>
          <div class="belgedurumu" :class="fileStatus">
            {{
              {
                NEW: "EKLENDİ",
                PENDING: "ONAY BEKLİYOR",
                CONFIRMED: "ONAYLANDI",
                REJECTED: "REDDEDİLDİ",
              }[fileStatus]
            }}
          </div>
          <!-- <div class="belgedurumu PENDING CONFIRMED REJECTED NEW ">
            EKLENDİ
          </div> -->
        </div>
      </div>

      <div
        v-if="editButtons && !CONFIRMED"
        class="markasil"
        @click="$emit('duzenle')"
      >
        Düzenle
      </div>
      <!-- <div
        v-if="editButtons"
        class="markasil"
        @click="$emit('sil')"
      >
        Sil
      </div> -->
      <div
        v-if="!brand.master && editButtons && !CONFIRMED"
        class="markasil"
        @click="$emit('sil')"
      >
        Sil
      </div>

      <div v-if="showBrandStatus" class="markadurum">
        {{
          {
            NEW: "EKLENDİ",
            PENDING: "ONAY BEKLİYOR",
            CONFIRMED: "ONAYLANDI",
            REJECTED: "REDDEDİLDİ",
          }[brand.brandStatus]
        }}
      </div>
    </div>

    <div class="detailline" v-show="false">
      <div
        class="detail"
        v-if="parseInt(brand.props.text) || alfaNumerics.length"
      >
        <div class="detleft" v-show="false">
          <div class="contitle">Mesajla ulaşılan alıcı sayısı</div>
          <div class="markasayi">
            {{ (parseInt(brand.props.text) || 0) | toIntWithDot }}
          </div>
        </div>
        <div class="detright">
          <div class="contitle">Gönderen isimleri</div>
          <div class="condetail">
            <div
              class="mark"
              v-for="(code, index) in alfaNumerics"
              :key="index"
            >
              {{ code }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="detail"
        v-if="parseInt(brand.props.voice) || phoneNumbers.length"
      >
        <div class="detleft" v-show="false">
          <div class="contitle">Aramayla ulaşılan alıcı sayısı</div>
          <div class="markasayi">
            {{ (parseInt(brand.props.voice) || 0) | toIntWithDot }}
          </div>
        </div>
        <div class="detright">
          <div class="contitle">Telefon numaraları</div>
          <div class="condetail">
            <div
              class="mark"
              v-for="(phone, index) in phoneNumbers"
              :key="index"
            >
              {{ phone }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="detail"
        v-if="parseInt(brand.props.mail) || emailAddresses.length"
      >
        <div class="detleft" v-show="false">
          <div class="contitle">E-postayla ulaşılan alıcı sayısı</div>
          <div class="markasayi">
            {{ (parseInt(brand.props.mail) || 0) | toIntWithDot }}
          </div>
        </div>
        <div class="detright">
          <div class="contitle">E-posta adresleri</div>
          <div class="condetail">
            <div
              class="mark"
              v-for="(email, index) in emailAddresses"
              :key="index"
            >
              {{ email }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="reject-reason" v-if="brand.brandStatus === 'REJECTED'">
      <b>Ret Sebebi: </b>{{ brand.rejectionReason }}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "MarkaShow",
  props: {
    sil: {
      type: Boolean,
      default: true,
    },
    brand: {
      type: Object,
      required: true,
    },
    editButtons: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState("applicant", ["application"]),

    showBrandStatus() {
      if (this.brand.brandStatus === "NEW") return false;
      if (this.hasFile) {
        return false;
      } else {
        return true;
      }
    },

    CONFIRMED() {
      return this.brand.brandStatus === "CONFIRMED";
    },

    hasFile() {
      return !!this.file;
    },

    file() {
      return this.application.fileSet.find(
        (e) => e.id !== null && e.id === this.brand.fileId
      );
    },

    fileStatus() {
      return this.file.fileStatus;
    },

    fileName() {
      if (this.file) {
        return this.file.fileName;
      } else {
        return "";
      }
    },

    phoneNumbers() {
      let e = this.brand.props.phoneNumbers;
      return !Array.isArray(e) ? e.split(",") : e;
    },

    alfaNumerics() {
      let e = this.brand.props.alfaNumerics;
      return !Array.isArray(e) ? e.split(",") : e;
    },

    emailAddresses() {
      let e = this.brand.props.emailAddresses;
      return !Array.isArray(e) ? e.split(",") : e;
    },
  },
};
</script>

<style scoped>
.rejected {
  border: 1px solid #ce2f27 !important;
  background: #fffdfd !important;
}
.reject-reason {
  background: #f1e2e1;
  color: #ce2f27;
  margin-top: 1vw;
  padding: 0.5vw;
  border-radius: 0.5vw;
}
.rejected .belge {
  background: #f1e2e1 !important;
}
.rejected .markasil {
  color: #ce2f27 !important;
}

.markaall {
  border: 1px #f5f5f5 solid;
  padding: 2%;
  border-radius: 0.5vw;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  margin-bottom: 2vh;
}

.markaline {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.2vw;
  position: relative;
}
.markaname {
  width: 100%;
  padding-left: 0.5vh;
  font-weight: 700;
  font-size: 1.8rem;
  color: #55565e;
}

.markasil {
  margin: 0 0 0 0.2vw;
  text-align: center;
  color: #4da0df;
  border-radius: 0.5vw;
  background: #ffff;
  cursor: pointer;
  padding: 0.2vw 0.4vw;
  position: relative;
  right: 0;
}

.markasil:hover {
  background: #fdfbfa;
  color: #4da0df;
}

.markahead {
  font-weight: 500;
  color: #3d3d3d;
  background: #f3f3f3;
}
.markahead .markasayi {
  font-size: 0.8rem;
}
.markahead .markaname {
  font-size: 0.8rem;
}

.markainfo {
  color: #4da0df;
}

.markadurum { color: #55565e; text-align: right;}

.markainfo .belge {
  display: flex;
  align-items: center;
  background: #fdfbfa;
  padding: 5px 25px 5px 5px;
  color: #55565e;
  margin-right: 0.5vw;
  border-radius: 0.5vw;
  /* border-right: 1px #c9cfdf solid; */
}

.markainfo .belge .bimg {
  height: 39px !important;
  width: 39px !important;
  padding-right: 5px;
}
.markainfo .belge .belgename {
  white-space: nowrap;
}

.markainfo .belge .belgedurumu {
  font-size: 0.8rem;
  font-weight: 800;
}

.markainfo .belge .belgedurumu.NEW {
  color: #4da0df;
}
.markainfo .belge .belgedurumu.PENDING {
  color: #ed9f15;
}
.markainfo .belge .belgedurumu.REJECTED {
  color: #ce2f27;
}
.markainfo .belge .belgedurumu.CONFIRMED {
  color: #229f37;
}

.markahead .markasil {
  background: none !important;
}

.detailline {
  width: 100%;
  display: block;
  position: relative;
}
.detailline .detail {
  width: 100%;
  display: flex;
  padding: 0.5vw 0.2vw;
  /* border-top: #e7e7e7 1px solid; */
  align-items: flex-end;
  flex-wrap: wrap;
}
.detailline .detail .detleft {
  display: flex;
  width: 100%;
  border-bottom: #f7f7f7 1px solid;
  padding: 0 0.5vw;
  margin-right: 0.5vw;
  justify-content: space-between;
  align-items: flex-end;
}
.detailline .detail .detright {
  width: 100%;
  padding: 0 0.5vw;
}

.detailline .detail .detright .contitle {
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  margin: 1vw 0 0.4vw 0;
  color: #c2c2c2;
}
.detailline .detail .condetail {
  width: 80%;
}

.detailline .detail .condetail .mark {
  display: inline-flex;
  background: #fdfbfa;
  color: #4da0df;
  padding: 0.2vw 0.5vw;
  margin: 3px;
  border-radius: 0.5vw;
}

.detailline .detail .detleft .contitle {
  font-size: 1.2rem;
  color: #55565e;
  font-weight: 500;
}
.detailline .detail .markasayi {
  font-size: 1.4rem;
  color: #55565e;
  font-weight: 700;
}

@media only screen and (min-height: 1000px) {
}

/*  on tablets portrait ********************************************************************************************/
@media (orientation: portrait) and (min-width: 501px) {
  .markaall {
    margin-bottom: 2vh;
    padding: 2vw;
    border-radius: 1vw;
    box-shadow: 0 2px 5px #d4d4d4;
  }
  .detailline .detail .condetail .mark {
    padding: 1vw;
    margin: 3px;
    border-radius: 1vw;
  }
}

/*  on phones ********************************************************************************************/
@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .markaall {
    margin-bottom: 2vh;
    padding: 2vw;
    border-radius: 1vw;
    box-shadow: 0 0px 1px #d4d4d4;
  }
  .detailline .detail .condetail .mark {
    padding: 1vw;
    margin: 3px;
    border-radius: 1vw;
  }
  .markaline {
    flex-wrap: wrap;
  }
  .detailline .detail {
    flex-wrap: wrap;
    margin: 2vh 0;
  }
  .detailline .detail .condetail {
    width: 100%;
    padding: 2vw 0;
  }
  .detailline .detail .detleft {
    width: 100%;
    border-right: 0px #c9cfdf solid;
    padding-right: 0vw;
    margin-right: 0vw;
    padding-top: 2vw;
    border-bottom: 1px #c9cfdf solid;
  }
  .detailline .detail .detright {
    width: 100%;
    padding-top: 2vw;
  }

  .markainfo .belge {
    width: 100%;
    border-radius: 2vw;
  }

  .markasil {
    width: 48%;
    padding: 2vh;
    background: #f3f3f3;
    margin: 2vh 1%;
    border-radius: 2vw;
  }
}
</style>
