<template>
  <div class="formpart mt">
    <div class="formleft">
      <h3>Ticari Bilgiler</h3>

      <div class="card" action="#">

        <div class="line">
          <label>Ticaret Unvanı</label>
          <div class="text">{{ application.title }}</div>
        </div>

        <div class="line" :class="detay_class">
          <label>Vergi Kimlik Numarası</label>
          <div class="text">{{ application.vergiNo | tovergiNo }}</div>
        </div>

        <div class="line" :class="detay_class">
          <label>Vergi Dairesi</label>
          <div class="text">{{ application.vergiDairesi }}</div>
        </div>

        <div class="line" :class="detay_class">
          <label>MERSİS Numarası</label>
          <div class="text">{{ application.mersisNo | tomersisNo }}</div>
        </div>

        <div class="line" :class="detay_class">
          <label>Web Sitesi</label>
          <div class="text">{{ application.website }}</div>
        </div>

        <div class="line" :class="detay_class">
          <label>İletişim Numarası</label>
          <div class="text">{{ application.officePhone | tocontactCellPhone }}</div>
        </div>

        <div class="line" :class="detay_class">
          <label>İletişim Adresi</label>
          <div class="text">
            <!-- {{application.address | toAddress}} -->
            {{ application.address.explicitAddress }}
            <!-- {{ application.address.district.name }} Mah. {{ application.address.town.name }}/{{
              application.address.city.name
            }} -->
          </div>
        </div>




      </div>
    </div>
    <div class="formright">
      <h3>Yetkili Bilgileri</h3>
      <div class="card" action="#">
        <div class="line">
          <label>Ad Soyad</label>
          <div class="text">{{ application.contact.name }}</div>
        </div>

        <div class="line">
          <label>TC Kimlik Numarası</label>
          <div class="text">
            {{ application.contact.tcKimlikNo | tocontactTcKimlikNo }}
          </div>
        </div>

        <div class="line" :class="detay_class">
          <label>E-posta Adresi</label>
          <div class="text">{{ application.contact.email }}</div>
        </div>

        <div class="line" :class="detay_class">
          <label>Cep Telefonu</label>
          <div class="text">
            {{ application.contact.cellPhone | tocontactCellPhone }}
          </div>   
        </div>

      </div>
      
  
      
    </div>

            <div class="detaybtn center" @click="detay_class=='short'?detay_class='long':detay_class='short'">
      <a v-if="detay_class=='short'">Detayları Gör</a>
      <a v-if="detay_class=='long'">Detayları Gizle</a>
    </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {

  data () {
    return {
      detay_class : "short" // long
    }
  },

  name: "BilgiBoxes",
  computed: {
    ...mapState("applicant", ["application"])
  }

  
};
</script>

<style scoped>

.formpart {flex-wrap: wrap; }

.detaybtn {text-align: center;}

.line  { display: block; position: relative; opacity: 1;}

.short  { height: 0 !important;
    max-height: 0 !important;
    overflow: hidden;
    margin: 0;}

.long {}

.card {height: 100%;}

* { transition: all 1s ease !important;}

</style>
